import React from "react"
// import journeyLang from '../data/journeyLang'
// import { getUserLangKey } from 'ptz-i18n';
import { navigate } from '../helpers/queryNavigate'
import isBrowser from '../helpers/isBrowser'


const LanguagePage = ({ pageContext }) => {
    const defaultLang = 'es'
    // const { journey } = pageContext
    // const langsObject = (journeyLang[journey.toLowerCase()] || [{ es: 'esES' }])
    // let langs = []
    // for (let lang in langsObject) {
    //     langs.push(lang) // agregamos la llava que ahi viene el idioma
    // }

    if (isBrowser()) {
        // const langKey = getUserLangKey(langs, defaultLang);
        const langKey = defaultLang;

        navigate(`/${langKey}`)
    }


    return (<React.Fragment />)
}

export default LanguagePage